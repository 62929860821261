import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"

import SEO from "../../components/seo"
import { ChevronRight } from "react-feather"
import Front from "../../images/front-vertical.svg"
import FrontApp from "../../images/front-app.svg"
import FrontVideo from "../../videos/aide-front.mp4"
import AideSetup from "../../videos/aide-setup.mp4"

import Input from "../../components/Input/Input"

import SignupModal from "../../Features/SignupModal"

const FrontIntegration = () => {
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [isEmailFocused1, setIsEmailFocused1] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [email1, setEmail1] = useState("")
  const [emailError1, setEmailError1] = useState("")
  return (
    <Layout>
      <SEO
        title="Accelerate your customer support team"
        description="Use our AI integrations to instantly generate responses for your customer support team"
      />
      <SignupModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        modalUpdatedSucess={() => {
          setIsModalOpen(false)
        }}
        email={email1.length > 0 ? email1 : email}
      />
      <div className="home-container">
        <div className="hero-nav">
          <div className="breadcrumb">
            <Link to="/integrations">Integrations</Link>{" "}
            <ChevronRight className="access-chevron" />{" "}
            <span className="current">Front </span>
          </div>
        </div>
        <div className="hero first-hero">
          <div className="integration-left">
            <img src={Front} className="integration-logo-large" />

            <h1 className="integration-h">Front Inbox Integration</h1>
            <p className="integration-p">
              Instantly generate relevant responses and respond to customer
              requests in a fraction of the time
            </p>
            {/* <div className="requirements">
              <div className="requirement">
                <a href="https://frontapp.com/pricing">
                  * Requires Front Plus, Prime, or Enterprise plan
                </a>
              </div>
            </div> */}
            <div className="access last-access" style={{marginTop: '2rem'}}>
              <div style={{ width: "52%" }}>
                <Input
                  className="access-field"
                  type="email"
                  placeholder="enter your email"
                  autoFocus={false}
                  value={email1}
                  onChange={e => {
                    setEmail1(e.target.value)
                  }}
                  haserror={emailError1.length > 0 ? true : false}
                  style={{
                    minWidth: "100%",
                    display: "block",
                  }}
                />
                {emailError1 && (
                  <span className="email-error"> {emailError1}</span>
                )}
              </div>
              <button
                className={`access-button darkens dark-button ${
                  isEmailFocused1 ? "active" : ""
                }`}
                onClick={() => {
                  setEmailError1("")
                  if (email1.length === 0) {
                    setEmailError1("Email is required")
                  } else if (
                    !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email1)
                  ) {
                    setEmailError1("Email is invalid")
                  } else {
                    // Assume all good and show the modal
                    setIsModalOpen(true)
                  }
                }}
              >
                Request access <ChevronRight className="access-chevron" />
              </button>
            </div>
          </div>

          <div className="hero-right">
            <img src={FrontApp} className="front-app" />
          </div>
        </div>
        <div className="clipper">
          <div className="integration-hero">
            <div className="integration-details">
              <h2 className="big-h-2">Installing the integration</h2>
              <p className="big-p-2">
                You'll find our integration in the Front integrations directory,
                here. Alternatively, if you're looking to install it from within
                your inbox in the Front app, simply follow the directions
                outlined in the below video:{" "}
              </p>
              <div className="front-video-container">
                <video controls>
                  <source src={FrontVideo} type="video/mp4" />
                </video>
              </div>

              <h2 className="big-h-2">Setting up Aide</h2>
              <p className="big-p-2">
                Once you've installed Aide in Front{" "}
                <a href="#"
                onClick={() => {
                    setIsModalOpen(true)
                  }
                }
              > let us know
                </a>
                , you will then go through our onboarding process, where you'll
                provide the following information:
              </p>
              <ul>
                <li>Your company's name and website</li>
                <li>
                  {" "}
                  Your product types (whether physical products that are shipped
                  or digital software and services) their descriptions, and
                  links to them
                </li>
                <li>
                  2 examples of support emails that you receive and your
                  responses to them{" "}
                </li>
                <li>
                  Your primary support email and physical mailing address (for
                  shipping returns if your customers ask about them)
                </li>
              </ul>

              <p className="big-p-2">
                You can see the steps required for setup in the below video:
              </p>
              <div className="front-video-container">
                <video controls>
                  <source src={AideSetup} type="video/mp4" />
                </video>
              </div>

              <p className="big-p-2">
                Once you're provided us with the above information, we will
                create and train an AI model for you to automatically generate
                responses on Front using the integration you've installed in the
                previous step. Generally, this process takes us a few hours if
                you've completed the process during business hours and up to 12
                hours if not.
              </p>
            </div>
            <div className="all-integrations">
              <a href="/integrations">
                See all integrations <ChevronRight className="access-chevron" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default FrontIntegration
